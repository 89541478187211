import type { NextPage } from 'next';
import { ComponentProps } from 'react';
import Page from './Page';
import Footer from './modules/Footer';
import Navbar from './components/Navbar';
import PageMeta, { PageMetaProps } from './components/PageMeta';

export type Props = {
  isIndex: boolean,
  pageData: ComponentProps<typeof Page>,
  navbarData: ComponentProps<typeof Navbar>,
};

const DefaultPage: NextPage<Props> = ({ pageData, navbarData }: Props) => {
  const firstModule = pageData.modules[0];

  const extraPageMeta: Partial<PageMetaProps> = {};

  if (firstModule) {
    if ('theme' in firstModule) {
      extraPageMeta.theme = firstModule.theme;
    }

    switch (firstModule.intunioModuleTypeId) {
      case 'subpage_header':
        extraPageMeta.pageBg = firstModule.backgroundImage;
        extraPageMeta.pageBgColor = firstModule.backgroundColor;
        break;
      default: break;
    }
  }

  return (
    <div>
      <PageMeta {...pageData} imageUrl={pageData.ogImage?.url} {...extraPageMeta} />
      <Page {...pageData} navbarData={{ ...navbarData, transparent: true }} />
      <Footer {...navbarData} />
    </div>
  );
};

export default DefaultPage;
