import cms from '../lib/contentful';
import DefaultPage, { Props } from '../src/DefaultPage';

export const getStaticProps: () => Promise<{props: Props}> = async () => {
  const pageData = await cms.getWelcomePage();
  const navbarData = await cms.getNavbar();
  return {
    props: {
      isIndex: true,
      pageData: pageData.page,
      navbarData: navbarData.menu,
    },
  };
};

export default DefaultPage;
